var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wrapper"},[_c('nav',{attrs:{"id":"sidebar"}},[_c('ul',{staticClass:"list-unstyled components"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('li',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Sair")])])])]),_c('div',{attrs:{"id":"content"}},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light"},[_c('div',{staticClass:"container-fluid"},[_c('button',{staticClass:"btn btn-outline-dark",attrs:{"type":"button","id":"sidebarCollapse"},on:{"click":function($event){return _vm.clique()}}},[_c('span',{staticClass:"navbar-toggler-icon"})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-header"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/img/logo-white.png")}}),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"acessos"}},[_vm._v("Acessos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"tarefas"}},[_vm._v("Checagem Fecha Mês")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"napp"}},[_vm._v("Checagem Napp")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"dashboard"}},[_vm._v("Dashboard")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"usuarios"}},[_vm._v("Gestão de Usuários")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"implantacoes"}},[_vm._v("Implantações")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"replicacoes"}},[_vm._v("Replicação")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"wiki"}},[_vm._v("Wiki")])])
}]

export { render, staticRenderFns }